/**
 * checkout-shipments.js
 */

const radios = document.querySelectorAll('.checkout_shipments input[type="radio"]');

function shipmentsChange() {
  const radio = this;
  let index;
  for (let i = 0; i < radios.length; i++) {
    if (radios[i] === radio) {
      index = i;
    }
  }
  // choose
  if (index !== undefined) {
    const chooses = document.querySelectorAll('.checkout_shipments_choose');
    for (const choose of chooses) {
      choose.classList.remove('active');
    }
    chooses[index].classList.add('active');
  }
}

function shipmentsListen() {
  for (const radio of radios) {
    radio.addEventListener('change', shipmentsChange.bind(radio));
  }
}
shipmentsListen();

for (const radio of radios) {
  if (radio.checked) {
    shipmentsChange.bind(radio)();
  }
}
