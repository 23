/**
 * sylius-address-book.js override
 */

function addressBookChange() {
  const btn = this;
  const form = btn.closest('.checkout_section');
  const parseKey = function parseKey(key) {
    return key.replace(/(_\w)/g, words => words[1].toUpperCase());
  };
  const findByName = function findByName(name) {
    return form.querySelector('[name*=' + parseKey(name) + ']');
  };
  for (const item of form.querySelectorAll('input, select')) {
    item.value = null;
  }
  const obj = JSON.parse(JSON.stringify(btn.dataset));
  for (const key in obj) {
    const property = key;
    const value = obj[key];
    const field = findByName(property);

    // set value
    if (field) {
      field.value = value;
      if (property.indexOf('countryCode') !== -1) {
        field.dispatchEvent(new CustomEvent('change'));
        const provinceCode = btn.getAttribute('data-province-code');
        const provinceName = btn.getAttribute('data-province-name');
        const provinceContainer = form.querySelector('.province-container');
        const exists = setInterval(() => {
          const provinceCodeField = findByName('provinceCode');
          const provinceNameField = findByName('provinceName');

          if (!provinceContainer.getAttribute('data-loading')) {
            if (provinceCodeField && (provinceCode !== '' || provinceCode !== undefined)) {
              provinceCodeField.value = provinceCode;

              clearInterval(exists);
            } else if (provinceNameField && (provinceName !== '' || provinceName !== undefined)) {
              provinceNameField.value = provinceName;

              clearInterval(exists);
            }
          }
        }, 100);
      }
    }

  }
}

function addressBook(select) {
  const btns = select.querySelectorAll('.drop button');
  for (const btn of btns) {
    btn.addEventListener('click', addressBookChange.bind(btn));
  }
}

for (const select of document.querySelectorAll('.address-book-select')) {
  addressBook(select);
}
