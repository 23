/**
 * @sylius-cart-update-custom
 */

const syliusCartUpdateCustom = document.querySelector('#sylius-cart-update-custom');

if (syliusCartUpdateCustom) {
  syliusCartUpdateCustom.addEventListener('click', function (e) {
    $('#sylius-cart-update').trigger('click'); // needs jquery event
  });
}

/**
 * .cart_update
 */

const inputs = document.querySelectorAll('#sylius-cart-items input[type="number"]');
const update = document.querySelector('.cart_update');
for (const input of inputs) {
  input.addEventListener('change', function() {
    update.classList.add('visible');
  });
}
