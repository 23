
/**
 * @sylius-variant-prices
 */

function setProductVariantPrice(container, btn) {
  const priceContainer = container.querySelector('#product-price');
  const price = btn.getAttribute('data-variant-price');
  priceContainer.innerHTML = price;
}

function handleProductVariantPrices(container) {
  // swatches
  const btns = container.querySelectorAll('[data-variant-price]');
  if (btns.length) {
    // event
    for (const btn of btns) {
      btn.addEventListener('click', (e) => {
        setProductVariantPrice(container, e.currentTarget);
      });
    }
  }
}

for (const container of document.querySelectorAll('[data-variant-container]')) {
  handleProductVariantPrices(container);
}
