/**
 * sylius-address-type.js custom
 */

function addressTypeChange() {
  const select = this;
  const val = select.value;
  const groupCompany = document.querySelector('#sylius_checkout_address_billingAddress_company').closest('.field');
  const groupVat = document.querySelector('#sylius_checkout_address_billingAddress_vatNumber').closest('.field');
  const groupFiscal = document.querySelector('#sylius_checkout_address_billingAddress_fiscalCode').closest('.field');
  const groupSidPec = document.querySelector('#sylius_checkout_address_billingAddress_sdiCode').closest('.two.fields');
  if (val === 'billing_company') {
    groupCompany.classList.remove('display--none');
    groupVat.classList.remove('display--none');
    groupFiscal.classList.remove('display--none');
    groupSidPec.classList.remove('display--none');
  } else if (val === 'billing_individual') {
    groupCompany.classList.add('display--none');
    groupVat.classList.add('display--none');
    groupFiscal.classList.remove('display--none');
    groupSidPec.classList.add('display--none');
  } else {
    groupCompany.classList.add('display--none');
    groupVat.classList.add('display--none');
    groupFiscal.classList.add('display--none');
    groupSidPec.classList.add('display--none');
  }
}

function addressType(select) {
  select.addEventListener('change', addressTypeChange.bind(select));
  addressTypeChange.bind(select)();
}

for (const select of document.querySelectorAll('#sylius_checkout_address_billingAddress_type')) {
  addressType(select);
}

/**
 * sylius-address-type add required
 */

function addressTypeRequired(el)  {
  if (el) {
    el.innerHTML = el.innerHTML + '<span class="required"></span>';
  }
}

addressTypeRequired(document.querySelector('[for="sylius_checkout_address_billingAddress_fiscalCode"]'));
addressTypeRequired(document.querySelector('[for="sylius_checkout_address_billingAddress_company"]'));
addressTypeRequired(document.querySelector('[for="sylius_checkout_address_billingAddress_vatNumber"]'));
