
/**
 * @sylius-variant-images
 * sylius [data-gallery-change-main] change #main-image
 * search all "data-gallery-" on markup
 */

function handleProductGallery(container) {
  const thumbs = container.querySelectorAll('[data-gallery-change-main]');
  if (!thumbs.length) {
    return false;
  }
  const imageMain = container.querySelector('[data-gallery-main]');
  const loaderMain = container.querySelector('[data-gallery-main-loader]');
  const imageZoom = document.querySelector('[data-gallery-zoom]');
  const loaderZoom = document.querySelector('[data-gallery-zoom-loader]');
  // event
  for (const thumb of thumbs) {
    thumb.addEventListener('click', (e) => {
      const loaderOn = function (loader) {
        loader.classList.add('active');
        loader.classList.remove('out');
      };
      const loaderOff = function (loader) {
        loader.classList.remove('active');
        loader.classList.add('out');
      };
      // image
      if (imageMain) {
        loaderOn.bind(imageMain, loaderMain)();
        imageMain.setAttribute('src', thumb.getAttribute('data-gallery-change-main'));
        if (imageMain.complete) {
          loaderOff.bind(imageMain, loaderMain)();
        } else {
          imageMain.removeEventListener('load', loaderOff.bind(imageMain, loaderMain));
          imageMain.removeEventListener('error', loaderOff.bind(imageMain, loaderMain));
          imageMain.addEventListener('load', loaderOff.bind(imageMain, loaderMain));
          imageMain.addEventListener('error', loaderOff.bind(imageMain, loaderMain));
        }
      }
      if (imageZoom) {
        loaderOn.bind(imageZoom, loaderZoom)();
        imageZoom.setAttribute('src', thumb.getAttribute('data-gallery-change-zoom'));
        if (imageZoom.complete) {
          loaderOff.bind(imageZoom, loaderZoom)();
        } else {
          imageZoom.removeEventListener('load', loaderOff.bind(imageZoom, loaderZoom));
          imageZoom.removeEventListener('error', loaderOff.bind(imageZoom, loaderZoom));
          imageZoom.addEventListener('load', loaderOff.bind(imageZoom, loaderZoom));
          imageZoom.addEventListener('error', loaderOff.bind(imageZoom, loaderZoom));
        }
      }
    });
  }
  return true;
}

for (const container of document.querySelectorAll('[data-gallery-container]')) {
  handleProductGallery(container);
}

/**
 * sylius thumb variants
 * search all "data-variant-" on markup
 * // @FIX variant input value
 * // @FIX variant gallery
 */

function handleProductVariant(btns, btn) {
  const container = btn.closest('[data-variant-container]');
  const gallery = container.querySelector('[data-gallery-container]');
  if (!gallery) {
    return false;
  }
  // deactivate
  for (const b of btns) {
    b.classList.remove('active');
  }
  const thumbs = gallery.querySelectorAll('[data-gallery-change-main]');
  for (const thumb of thumbs) {
    if (thumb.querySelectorAll('[data-variant-code]').length) { // filter out not variants
      thumb.classList.remove('visible');
    }
  }
  // change thumbs
  btn.classList.add('active');
  const current = btn.getAttribute('data-variant-change');
  const variants = gallery.querySelectorAll('[data-variant-code="' + current + '"]');
  for (const [i, variant] of variants.entries()) {
    const thumb = variant.closest('[data-gallery-change-main]');
    thumb.classList.add('visible');
    if (i === 0) {
      thumb.dispatchEvent(new CustomEvent('click'));
    }
  }
  // change link
  const links = container.querySelectorAll('[data-variant-link]');
  for (const link of links) {
    const url = new URL(link.getAttribute('href'), window.location.href);
    url.hash = current;
    link.setAttribute('href', url.href);
  }
  // @FIX variant input value
  const radio = document.querySelector('input[value="' + current + '"]');
  if (radio) {
    radio.checked = true;
    window.location.hash = current;
  }
  // add to cart not available
  const addToCartBtn = document.querySelector('#sylius-product-adding-to-cart .btn-cart');
  if (addToCartBtn) {
    addToCartBtn.classList.remove('not-available');
    if (btn.classList.contains('not-available')) {
      addToCartBtn.classList.add('not-available');
    }
  }
  return true;
}

function handleProductVariants(container) {
  // not swatches
  const gallery = container.querySelector('[data-gallery-container]');
  const thumbs = gallery.querySelectorAll('[data-gallery-change-main]');
  for (const [i, thumb] of thumbs.entries()) {
    if (!thumb.querySelectorAll('[data-variant-code]').length) { // filter out variants
      // always activate not variant
      thumb.classList.add('visible');
      if (i === 0) {
        thumb.dispatchEvent(new CustomEvent('click'));
      }
    }
  }
  // swatches
  const btns = container.querySelectorAll('[data-variant-change]');
  if (btns.length) {
    // event
    for (const btn of btns) {
      btn.addEventListener('click', (e) => {
        handleProductVariant(btns, e.currentTarget);
      });
    }
    // hash
    let foundHash = false;
    const onHashChange = function () {
      if (window.location.hash) {
        const current = decodeURI(window.location.hash.split('#')[1]);
        // @FIX variant input value
        const radio = document.querySelector('input[value="' + current + '"]');
        if (radio) {
          foundHash = true;
          radio.checked = true;
          const btn = document.querySelector('[data-variant-change="' + current + '"]');
          btn.dispatchEvent(new CustomEvent('click'));
        }
      }
    };
    window.onpopstate = function() {
      onHashChange();
    };
    onHashChange();
    if (!foundHash) {
      // trigger first
      handleProductVariant(btns, btns[0]);
    }
  }
}

for (const container of document.querySelectorAll('[data-variant-container]')) {
  handleProductVariants(container);
}
