/**
 * import
 */

import BezierEasing from 'bezier-easing';
import {TweenMax} from 'gsap/TweenMax';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import Xt from 'xtend-library';

/**
 * vars
 */

window.easeIn = new Ease(BezierEasing(1, 0, .64, 1));
window.easeOut = new Ease(BezierEasing(.36, 0, 0, 1));
window.easeInOut = new Ease(BezierEasing(.14, .63, 0, 1));

window.timeTiny = .25;
window.timeSmall = .5;
window.timeMedium = .75;
window.timeBig = 1;
window.timeGiant = 2;

TweenLite.defaultOverwrite = 'all';

/**
 * btn span required
 */

Xt.observe.push({
  matches: '.ui.button',
  fnc: function fn(main, index, query) {

    main.innerHTML = '<span>' + main.innerHTML + '</span>'

  }
});

/**
 * icons
 */

Xt.observe.push({
  matches: '.icon.checkmark',
  fnc: function fn(main, index, query) {

    main.classList.add('icon-message-success')

  }
});

Xt.observe.push({
  matches: '.icon.remove',
  fnc: function fn(main, index, query) {

    main.classList.add('icon-message-error')

  }
});

/**
 * xtend extension: mask
 */

window.divRotate = (outer, inner, angle) => {
  const angleCos = Math.abs(Math.cos(angle * Math.PI / 180));
  const angleSin = Math.abs(Math.sin(angle * Math.PI / 180));
  inner.style.width = 'calc(100% * ' + angleCos + ' + 100% * ' + angleSin + ')';
  inner.style.height = 'calc(100% * ' + angleSin + ' + 100% * ' + angleCos + ')';
  outer.style.transform = 'rotate3d(0, 0, 1, ' + angle + 'deg)';
  outer.style.transformOrigin = '60% 60%';
  outer.style.left = -(100 * angleSin) + '%';
  outer.style.top = -(100 * angleSin) + '%';
};

Xt.observe.push({
  matches: '.mask',
  fnc: function fn(main, index, query) {

    // mask

    const mask = main.querySelector('.mask_cover');
    if (mask) {
      window.divRotate(mask, mask.firstChild, 15);
    }

    // activation

    function eventOn() {
      // mask
      if (mask) {
        const maskInner = mask.firstChild;
        TweenMax.set(maskInner, {opacity: 0, yPercent: '100%'});
        TweenMax.to(maskInner, window.timeMedium, {opacity: .3, yPercent: '0%', ease: window.easeInOut});
      }
      // img
      const images = main.querySelectorAll('.mask_img');
      for (const image of images) {
        TweenMax.to(image, window.timeMedium, {scale: 1.05, ease: window.easeOut});
      }
      // content
      const contents = main.querySelectorAll('.mask_content');
      for (const content of contents) {
        TweenMax.set(content, {y: 5});
        TweenMax.to(content, window.timeMedium, {opacity: 1, y: 0, ease: window.easeInOut})
          .delay(window.timeTiny);
      }
    }

    function eventOff() {
      if (!main.classList.contains('active')) {
        // mask
        if (mask) {
          const maskInner = mask.firstChild;
          TweenMax.to(maskInner, window.timeMedium, {opacity: 0, yPercent: '-100%', ease: window.easeInOut})
            .delay(window.timeTiny);
        }
        // img
        const images = main.querySelectorAll('.mask_img');
        for (const image of images) {
          TweenMax.to(image, window.timeMedium, {scale: 1, ease: window.easeOut});
        }
        // content
        const contents = main.querySelectorAll('.mask_content');
        for (const content of contents) {
          TweenMax.to(content, window.timeMedium, {opacity: 0, y: 5, ease: window.easeInOut});
        }
      }
    }

    // event

    main.addEventListener('mouseenter', eventOn);
    main.addEventListener('mouseleave', eventOff);
    main.addEventListener('click', eventOff);

  }
});

