/**
 * sylius-contact-type.js custom
 */

function contactTypeChange() {
  const select = this;
  const val = select.value;
  const groupRegistration = document.querySelector('#sylius_contact_registration_number').closest('.field');
  if (val === 'technical') {
    groupRegistration.classList.remove('display--none');
  } else {
    groupRegistration.classList.add('display--none');
  }
}

function contactType(select) {
  select.addEventListener('change', contactTypeChange.bind(select));
  contactTypeChange.bind(select)();
}

for (const select of document.querySelectorAll('#sylius_contact_reason')) {
  contactType(select);
}
