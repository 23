import Xt from 'xtend-library';
import Cookies from 'js-cookie';

/* auto open promotion overlay */

Xt.observe.push({matches: '#overlay--promotion',
  fnc(main, index, query) {
    // vars

    const overlay = main;
    const ns = overlay.getAttribute('data-promotion-autoopen-ns') || '';
    const delay = overlay.getAttribute('data-promotion-autoopen-delay') || 0;

    // done

    const done = () => {
      Cookies.set(`${ns}-promotion-done`, true);
    };

    overlay.addEventListener('off.xt', done);

    // autoopen

    if (Cookies.get(`${ns}-promotion-firstpage`)) {
      if (!Cookies.get(`${ns}-promotion-done`)) {
        setTimeout(() => {
          overlay.dispatchEvent(new CustomEvent('on.xt'));
        }, delay);
      }
    }

    Cookies.set(`${ns}-promotion-firstpage`, true);
  }});
