/**
 * autoLoginInput
 */

const autoLoginInput = document.querySelector('#sylius-api-login-form input[type="password"]');
if (autoLoginInput) {
  const autoLoginBtn = document.querySelector('#sylius-api-login-form #sylius-api-login-submit');
  // on key enter instead of submittind checkout do login
  autoLoginInput.addEventListener('keydown', function (e) {
    if (e.keyCode === 13) {
      autoLoginBtn.dispatchEvent(new CustomEvent('click'));
      e.preventDefault();
    }
  });
}
