
/**
 * @sylius-variant-codes
 */

function setProductVariantCode(container, btn) {
  const codeContainer = container.querySelector('#variant_code');
  codeContainer.innerHTML = btn.getAttribute('data-variant-change');
}

function handleProductVariantCodes(container) {
  // swatches
  const btns = container.querySelectorAll('[data-variant-change]');
  if (btns.length) {
    // event
    for (const btn of btns) {
      btn.addEventListener('click', (e) => {
        setProductVariantCode(container, e.currentTarget);
      });
    }
  }
}

for (const container of document.querySelectorAll('[data-variant-container]')) {
  handleProductVariantCodes(container);
}
