import Cookies from 'js-cookie';
import objectFitImages from 'object-fit-images';
import 'picturefill';
import Xt from 'xtend-library';
import './animation';
import './googlelocator';
import './promotion-featured';
import './sylius/checkout-shipment';
import './sylius/sylius-address-book';
import './sylius/sylius-address-type';
import './sylius/sylius-contact-type';
import './sylius/sylius-cart';
import './sylius/sylius-checkout';
import './sylius/sylius-modal';
import './sylius/sylius-variant-prices';
import './sylius/sylius-variant-codes'; // this must be before "sylius-variant-images"
import './sylius/sylius-variant-images';

Xt.debug = true;

/**
 * https://github.com/bfred-it/object-fit-images
 */

objectFitImages();

/**
 * https://github.com/kamranahmedse/jquery-toast-plugin
 */

require('jquery-toast-plugin/src/jquery.toast.js');

window.toastDelay = 500;

function addToast(el) {
  if (!el.dataset.toastDone &&
    el.textContent.replace(/[\n\r]+|[\s]{2,}/g, '') !== '') { // @FIX checkout empty messages
    el.dataset.toastDone = true;
    var type;
    var string = el.innerHTML;
    if (el.classList.contains('error')) {
      type = 'error';
    } else if (el.classList.contains('success')) {
      type = 'success';
    } else {
      type = 'info';
    }
    window.toastDelay += 250;
    window.setTimeout(function () {
      $.toast({
        text: string,
        hideAfter: false,
        icon: type,
        stack: 5,
        loader: false,
        position: 'bottom-center',
        beforeShow: function beforeShow(item) {
          item.addClass('xt-ignore');
          window.toastDelay -= 250;
        },
        beforeHide: function beforeHide(item) {
          item.addClass('toast-hide');
        }
      });
    }, window.toastDelay);
  }
}

Xt.observe.push({
  matches: '.sylius-flash-message, .sylius-message',
  fnc: function (main, index, query) {

    addToast(main);

  }
});

/**
 * xt-smooth
 */

if (!document.querySelector('body').matches('.polyphoto_shop_product_register_standard_program')
  && !document.querySelector('body').matches('.polyphoto_shop_product_register_olympus_assistance_program')) {
  Xt.init('xt-smooth', document.scrollingElement, {});
}

/**
 * .slider--home
 */

Xt.observe.push({
  matches: '.slider--home',
  fnc: function (main, index, query) {

    // vars

    let timeImg = .75; // @FIX reset after off.xt
    let timeImgZoom = 10;
    let zoomImg = 1.05;

    // slider

    let self = Xt.init('xt-slider', main, {
      "drag": {
        "wrap": true,
        "friction": false,
        "overflow": false,
      },
      "auto": {
        "time": 6000,
      },
      "groupMq": false,
      "align": "left",
      "autoHeight": false,
    });

    // drag

    self.dragger.addEventListener('drag.xt.slider', function (e) {
      let target = self.targets.filter(x => x.classList.contains('active'))[0];
      let ratio = Math.abs(self.detail.xStart - self.detail.xCurrent) / target.clientWidth;
      let imgSize = self.dragger.offsetWidth / 6;
      // direction
      let direction = 1;
      if (self.detail.xStart - self.detail.xCurrent > 0) {
        direction = -1;
      }
      // others
      for (let tr of self.targets.filter(x => !x.classList.contains('active'))) {
        let img = tr.querySelector('.slider--home_img_inner');
        TweenMax.set(img, {x: (imgSize * direction) - (imgSize * ratio * direction), opacity: ratio + 0.5});
      }
      // img
      let img = target.querySelector('.slider--home_img_inner');
      TweenMax.set(img, {x: -imgSize * ratio * direction, opacity: 1 - ratio + 0.5});
    });

    // dragend

    self.dragger.addEventListener('dragreset.xt.slider', function (e) {
      let target = self.targets.filter(x => x.classList.contains('active'))[0];
      // img
      let img = target.querySelector('.slider--home_img_inner');
      TweenMax.to(img, timeImg, {x: 0, opacity: 1, ease: window.easeInOut});
    });

    // on

    self.object.addEventListener('on.xt', function (e) {
      let tr = e.target;
      if (self.targets.includes(tr)) { // event bubbles
        let img = tr.querySelector('.slider--home_img_inner');
        if (self.initial) {
          TweenMax.set(img, {x: 0, opacity: 1, scale: 1});
          //TweenMax.to(img, timeImgZoom, {scale: zoomImg, ease: Sine.easeInOut, repeat: -1, yoyo: true});
        } else {
          TweenMax.to(img, timeImg, {x: 0, opacity: 1, ease: window.easeInOut});
          //.eventCallback('onComplete', function () {
          //  TweenMax.to(img, timeImgZoom, {scale: zoomImg, ease: Sine.easeInOut, repeat: -1, yoyo: true});
          //});
        }
      }
    });

    // off

    self.object.addEventListener('off.xt', function (e) {
      let tr = e.target;
      if (self.targets.includes(tr)) { // event bubbles
        let imgSize = self.dragger.offsetWidth / 6;
        // direction
        let direction = 1;
        if (tr.classList.contains('inverse')) {
          direction = -1;
        }
        // img
        let img = tr.querySelector('.slider--home_img_inner');
        TweenMax.to(img, timeImg, {x: imgSize * direction, opacity: 0.5, ease: window.easeInOut})
          .eventCallback('onComplete', function () {
            TweenMax.set(img, {x: 0, opacity: 1, scale: 1}); // @FIX reset after off.xt
          });
      }
    });

  }
});

/**
 * .slider--products
 */

Xt.observe.push({
  matches: '.slider--products',
  fnc: function (main, index, query) {

    // slider

    let self = Xt.init('xt-slider', main, {
      "jump": false,
      "groupMq": false,
      "align": "left",
      "autoHeight": false,
      "drag": {
        "wrap": true,
        "friction": false,
      },
    });
    self.unmount = function () {
      self = null;
    };

  }
});

/**
 * .slider--events
 */

Xt.observe.push({
  matches: '.slider--events',
  fnc: function (main, index, query) {

    // slider

    let self = Xt.init('xt-slider', main, {
      "jump": false,
      "groupMq": false,
      "align": "left",
      "autoHeight": false,
      "drag": {
        "wrap": true,
      },
    });
    self.unmount = function () {
      self = null;
    };

  }
});

/**
 * .slider--home_item
 */

Xt.observe.push({
  matches: '.slider--home_item',
  fnc: function (main, index, query) {

    // scroll

    let self = Xt.init('xt-scroll', main, {
      "distance": -200
    });
    self.unmount = function () {
      self = null;
    };

    // event

    for (let el of self.elements) {

      // scroll change

      el.addEventListener('change.xt.scroll', function (e) {
        let imgFactor = .1;
        if (window.matchMedia('(max-width: 991px)').matches) {
          imgFactor = .2;
        }
        let img = el.querySelector('.slider--home_img_inner');
        TweenMax.set(img, {scale: 1 + imgFactor * self.detail.ratio, opacity: self.detail.ratioInverse});
        let content = el.querySelector('.slider--home_content');
        TweenMax.set(content, {y: 100 * self.detail.ratio});
      });

    }

  }
});

/**
 * .categories-banner_img
 */

Xt.observe.push({
  matches: '.categories-banner',
  fnc: function (main, index, query) {

    // scroll

    let self = Xt.init('xt-scroll', main, {
      "distance": -200
    });
    self.unmount = function () {
      self = null;
    };

    // event

    for (let el of self.elements) {

      // scroll change

      el.addEventListener('change.xt.scroll', function (e) {
        let imgFactor = .1;
        if (window.matchMedia('(max-width: 991px)').matches) {
          imgFactor = .2;
        }
        let img = el.querySelector('.categories-banner_img');
        TweenMax.set(img, {scale: 1 + imgFactor * self.detail.ratio, opacity: self.detail.ratioInverse});
        let content = el.querySelector('.categories-banner_logo');
        TweenMax.set(content, {y: 100 * self.detail.ratio});
      });

    }

  }
});

/**
 * xtend extension: cookie
 */

Xt.observe.push({
  matches: '.cookie_dismiss',
  fnc: function (main, index, query) {

    let cookie = main.closest('.cookie');
    let name = main.getAttribute('data-cookie-name');

    // hide

    let cookieAccepted = Cookies.get(name);
    if (!cookieAccepted) {
      cookie.style.display = 'block';
    }

    // dismiss

    main.addEventListener('click', function (e) {
      cookie.classList.add('out');
      Cookies.set(name, 'true', {expires: 365});
      Xt.animTimeout(cookie, function () {
        cookie.style.display = 'none';
      });
    });

  }
});

/**
 * #footer-newsletter-form
 */

Xt.observe.push({
  matches: '#footer-newsletter-form',
  fnc: function (main, index, query) {

    let form = main;
    form.addEventListener('submit', function (e) {
      let overlay = document.querySelector('#overlay--newsletter');
      overlay.dispatchEvent(new CustomEvent('on.xt'));
      overlay.querySelector('[type="email"]').value = this.querySelector('.form-input').value;
      e.preventDefault();
      return false;
    });

  }
});

/**
 * xt-scroll
 */

/*
// inject

let scrollsDefaults = document.querySelectorAll('.site_main, .product--list, .events--grid > .row');
for (let scrollsDefault of scrollsDefaults) {
  scrollsDefault.classList.add('scrolls--default');
}

//let scrollDefaults = document.querySelectorAll('');
//for (let scrollDefault of scrollDefaults) {
//  scrollDefault.classList.add('scroll--default');
//}

// activateScrolls after preload

let activateScrolls = function () {
  // xt-scroll
  let self = Xt.get('xt-scroll', document.documentElement);
  if (self) {
    self.initStart();
  }
};

let deactivateScrolls = function () {
  let fades = document.querySelectorAll('.scrolls--default > *, .scroll--default, .cookie, .jq-toast-wrap');
  for (let fade of fades) {
    fade.classList.remove('fade', 'scroll--once', 'scroll--visible');
    fade.classList.add('out', 'scroll--block');
  }
  // boxes
  //let boxes = document.querySelectorAll('#notice-cookie-block, .shipping-switch');
  //for (let box of boxes) {
  //  box.classList.add('out');
  //}
};

Xt.init('xt-scroll', document.documentElement, {
  "class": "fade",
  "elements": ".scrolls--default > *:not(.scroll--disable), .scroll--default:not(.scroll--disable)",
  "distance": 50,
  "delayOn": "return Math.min(current * 100, 600)",
  "delayOff": "return Math.min((total - current) * 100, 600)"
});
*/

/**
 * xtend extension: page-loader
 */

let pageHide = function (e) {
  let html = document.querySelector('html');
  html.classList.remove('preloaded', 'preloaded-delay');
  html.classList.add('preload-fade');
  html.dispatchEvent(new CustomEvent('preload.show.xt'));
  clearTimeout(parseFloat(html.dataset.preloadedTimeout));
  //deactivateScrolls();
  window.dispatchEvent(new CustomEvent('autoClose.xt'));
};

let pageShow = function (e) {
  let html = document.querySelector('html');
  html.classList.add('preloaded');
  html.dispatchEvent(new CustomEvent('preload.show.xt'));
  html.dataset.preloadedTimeout = setTimeout(function () {
    html.classList.add('preloaded-delay');
    html.dispatchEvent(new CustomEvent('preload.show.delay.xt'));
  }, 200).toString();
  //activateScrolls();
};

pageShow();

// exit intent

window.onbeforeunload = function (e) {
  //if (window.exitIntentActivated) {
    // exit intent confirm
  //  let result = confirm();
  //  if (!result){
  //    e.stopPropagation();
  //    e.stopImmediatePropagation();
  //  }
  //  return result;
  //}
  pageHide(e);
};

/**
 * Merge objects
 * @param {Array} arr Array of objects to merge
 * @returns {Object} Merged object
 */
Xt.mergeFix = function (arr) {
  const final = {}
  for (const obj of arr) {
    if (obj) {
      for (const [key, value] of Object.entries(obj)) {
        if (value !== null && typeof value === 'object' &&
          !Array.isArray(value) && // not array
          !value.nodeName && // not HTML element
          value !== window) { // not window
          final[key] = Xt.merge([final[key], value])
        } else {
          final[key] = value
        }
      }
    }
  }
  return final
}

/**
 * xtend extension: googlelocator
 */

window.initGooglelocator = function () {
  // init

  const object = document.querySelector('.googlelocator')
  const self = new Xt.Googlelocator(object, {
    initialLocate: !object.classList.contains('googlelocator-checkout'),
    markers: window.markers,
    map: {
      icon: '/assets/shop/assets/images/googlemaps/icon.png',
      cluster: {
        minimumClusterSize: 5,
        imagePath: '/assets/shop/assets/images/googlemaps/m'
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
    },
    formatData: {
      sort: function(self, locations) {
        if (self.searchInput.value === '') {
          locations.sort((a, b) => {
            if (a.marker.storeSign > b.marker.storeSign) {
              return 1;
            }
            if (b.marker.storeSign > a.marker.storeSign) {
              return -1;
            }
            return 0;
          })
        } else {
          locations.sort((a, b) => a.distance - b.distance)
        }
      },
      filter: function(self, marker, filter) {
        if (Array.isArray(marker.retailerServices)) {
          return marker[filter] || (marker.retailerServices && marker.retailerServices.includes(filter))
        }
        if (typeof marker === 'object' && marker !== null) {
          return marker.retailerServices.hasOwnProperty(filter)
        }
        // fallback: do not visualize the marker
        return false;
      },
      name: function(self, location, el) {
        let str = ''
        str += location.marker.storeSign
        if (!str || str === '') {
          el.remove()
        } else {
          el.innerHTML = str
        }
      },
      address: function(self, location, el) {
        let str = ''
        str += location.marker.street + ' - ' + location.marker.postcode + ' ' + location.marker.city
        if (!str || str === '') {
          el.remove()
        } else {
          el.innerHTML = str
        }
      },
      additional: function(self, location, el) {
        let str = ''
        if (location.marker.phoneNumber) {
          str += '<a href="tel:' + location.marker.phoneNumber + '">' + location.marker.phoneNumber + '</a>'
        }
        if (!str || str === '') {
          el.remove()
        } else {
          el.innerHTML = str
        }
      },
      direction: function(self, location, el) {
        let str = 'https://www.google.com/maps/dir/?api=1&destination='
        str += location.marker.storeSign.split(' ').join('+')
        str += '+' + location.marker.street.split(' ').join('+')
        str += '+' + location.marker.postcode.split(' ').join('+')
        str += '+' + location.marker.city.split(' ').join('+')
        el.setAttribute('href', str)
      },
      radio: function(self, location, el) {
        const items = el.closest('.googlelocator_items').querySelectorAll('.googlelocator_item')
        el.setAttribute('id', 'googlelocator_radio_' + items.length)
        el.setAttribute('data-marker', JSON.stringify(location.marker))
      },
      label: function(self, location, el) {
        const items = el.closest('.googlelocator_items').querySelectorAll('.googlelocator_item')
        el.setAttribute('for', 'googlelocator_radio_' + items.length)
      },
    },
  })

  // data-retailer-address-data-input-id

  const googlelocatorCheckout = document.querySelector('.googlelocator-checkout')
  if (googlelocatorCheckout) {
    const storeInput = document.querySelector('#' + googlelocatorCheckout.getAttribute('data-retailer-address-data-input-id'))

    function resetStoreInputValue() {
      // reset storeInput
      storeInput.value = ''
      console.debug('data-retailer-address-data-input-id:', storeInput.value)
    }

    function changedStoreInputValue() {
      object.classList.add('selected')
      const value = JSON.parse(storeInput.value)
      const strElement = object.querySelector('.googlelocator-checkout-selected-shop')
      let str = '<strong>' + value.storeSign + '</strong><br/>' + value.street + ' - ' + value.postcode + ' ' + value.city
      str += value.phoneNumber ? '<br/>' + '<a href="tel:' + value.phoneNumber + '">' + value.phoneNumber + '</a>' : ''
      strElement.innerHTML = str
      const btn = object.querySelector('.googlelocator-checkout-selected-button')
      btn.addEventListener('click', function () {
        resetStoreInputValue()
        const radios = self.itemsContainer.querySelectorAll('input[type="radio"]')
        for (const radio of radios) {
          if (radio.checked) {
            radio.checked = false
          }
        }
        object.classList.remove('selected')
      })
      console.debug('data-retailer-address-data-input-id:', JSON.parse(storeInput.value))
    }

    self.object.addEventListener('change.xt.googlelocator', function (e) {
      resetStoreInputValue()
      // on radio change
      const radios = self.itemsContainer.querySelectorAll('input[type="radio"]')
      for (const radio of radios) {
        radio.addEventListener('change', function () {
          if (radio.checked) {
            const val = radio.getAttribute('data-marker')
            storeInput.value = val
            changedStoreInputValue()
            document.scrollingElement.scrollTo(0, object.offsetTop - 100)
            document.scrollingElement.dispatchEvent(new CustomEvent('stop.wheel.xt'));
          }
        })
      }
    })

    if (storeInput.value) {
      changedStoreInputValue()
    }

  }

  // unmount

  return function unmount () {
    self.destroy()
  }
}

if (window.initedGooglelocator) {
  window.initGooglelocator()
}

/**
 * search
 */

const searchOpen = document.querySelector('.btn--search--open');
const searchClose = document.querySelector('.btn--search--close');

if (searchOpen && searchClose) {
  const container = searchOpen.closest('.header');
  const input = container.querySelector('input[type="search"]')

  function searchToggle() {
    if (container.classList.contains('search_open')) {
      container.classList.remove('search_open');
      input.blur();
    } else {
      container.classList.add('search_open');
      input.focus();
    }
  }

  searchOpen.addEventListener('click', searchToggle);
  searchClose.addEventListener('click', searchToggle);
}
