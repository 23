/**
 * @sylius-modal
 * node_modules/semantic-ui-css/components/modal.js
 * sylius-require-confirmation.js
 * sylius-bulk-action-require-confirmation.js
 */

const overlays = document.querySelectorAll('.overlay_outer');
for (const overlay of overlays) {

  // @FIX sylius #confirmation-button clear action

  overlay.addEventListener('off.xt', function () {
    $('#confirmation-button').off('click');
  });

  // show

  $(overlay).modal('setting', 'onShow', function () {
    overlay.dispatchEvent(new CustomEvent('on.xt'));
  });

  // hide

  $(overlay).modal('setting', 'onHide', function () {
    overlay.dispatchEvent(new CustomEvent('off.xt'));
  });

}
